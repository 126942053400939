<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="align-center">
        <div><b>24STREAM</b></div>
        <small>IMAGE CONVERTER</small>
      </div>

      <v-spacer />
    </v-app-bar>

    <v-main>
      <v-progress-linear v-if="loading" indeterminate color="yellow darken-2" />
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="url" label="URL" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn :disabled="!url.length || loading" @click="getImages">
                Submit
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-4 mb-4" />
          <v-row v-if="resultUrl.length">
            <v-col cols="12" md="6">
              <a :href="resultUrl" target="_blank">{{ resultUrl }}</a>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-main>
  </v-app>
</template>

<script>
import baseUrl from "./helpers/baseUrl";
import eventBus from "./helpers/eventBus";
import xhr from "./helpers/xhr";

export default {
  name: "App",

  components: {
    // HelloWorld,
  },

  data: () => ({
    valid: true,
    resultUrl: "",
    url: "",
    loading: false,
  }),
  methods: {
    async getImages() {
      this.loading = true;
      const { data } = await xhr({
        method: "get",
        url: `${baseUrl}/parser?url=${this.url}`,
      });
      console.log(data);
      if (data.success) {
        this.resultUrl = data.path ? data.path : null;
      } else {
        eventBus.$emit("showSnackbar", {
          type: "error",
          body: "Something went wrong",
        });
      }

      this.loading = false;
    },
  },
};
</script>
