import axios from "axios";

export default (options) => {
  const localOptions = {
    method: options.method || "get",
    url: options.url,
    headers: options.headers || {},
    data: options.data || options.formData || {},
    responseType: options.responseType,
  };

  if (localOptions.method) {
    localOptions.params = options.params || {};
  }

  if (options.formData) {
    localOptions.formData = options.formData;
  }

  return axios(localOptions);
};
